.floating-menu {
    position: fixed;
    top: 92%;
    left: 92%;
    transform: translateY(-50%);
    background-color: #f8f9fa;
    padding: 15px;
    z-index: 999;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .switch-container {
    position: fixed;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
  }