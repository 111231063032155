#appLayoutDiv .hideAppSiderBar {
    display:none;
}
#appLayoutDiv .hideAppHeader {
    display:none;
}
#appLayoutDiv .hideAppSideBarOptButton {
    display:none;
}
#appLayoutDiv .hideAppFooter {
    display:none;
}