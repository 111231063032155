.tooltip-container-red {
    position: relative;
    display: inline-block;
    margin-left: 50px;  
}

.tooltip-container-grey {
    position: relative;
    display: inline-block;
    margin-left: 10px;  
}

#tooltip-icon-red {
    color: red;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    top: 3px;
}

#tooltip-icon-grey {
    color: grey;
    font-size: 16px;
    position: relative;
    top: 2px;
}
