.standard-container__inner--reduced {
    width: 80%;
}
.bg--white {
    background-color: #fff;
}
.gap--encircle--large {
    padding: 30px;
}
.gap--bottom--default {
    padding-bottom: 1.875rem;
}
.gap--top--default {
    padding-top: 1.875rem;
}
.h2:first-child, h2:first-child {
    margin-top: -0.5rem;
}
.receipt__heading {
    color: #00313c;
    font-size: 2.4rem;
    margin: 0;
}
.gap--bottom--small {
    padding-bottom: 1.25rem;
}
.text--remove-margin {
    margin: 0;
}
.bg--light {
    background-color: #f0f0f0!important;
}
.gap--top--small {
    padding-top: 1.25rem;
}
.grid {
    display: flex;
    flex-wrap: wrap;
    margin: -30px -15px 0;
}
.transaction-details .grid__item {
    margin-top: 0;
}
.grid--2 .grid__item {
    width: calc(50% - 30px);
}
.gap--top--x-small {
    padding-top: 0.9375rem;
}
.btn--medium {
    padding: 0.5rem 0.9375rem;
    font-size: 1rem;
    line-height: 1.4375rem;
    height: auto;
    min-height: 0;
}
.btn--transparent {
    color: #004b87;
    border-color: #d9d9d9;
    background-color: transparent;
}